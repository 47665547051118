jQuery(document).ready(function($) {
	(function() {
		if($('.more_types_link').length != 0) {
			$('.more_types_link').click(function(e) {
				e.preventDefault();
				if($(this).hasClass('active')) { 
					$(this).removeClass('active');
				} else {
					$('.more_types_link').not(this).removeClass('active');
					$(this).addClass('active');
					$('.more_types_div').not($(this).parents('.add_type:first').find('.more_types_div')).stop(true, true).slideUp();
				}
				$(this).parents('.add_type:first').find('.more_types_div').stop(true, true);
				$(this).parents('.add_type:first').find('.more_types_div').slideToggle();
			})
		}
	})();
	
	$(function() {
	  $('a[href*="#"]:not([href="#"])').click(function() {
		if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
		  var target = $(this.hash);
		  target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
		  if (target.length) {
			var targetPost = ($(window).width() < 768) ?  target.offset().top : target.offset().top - $('.header-spacer').height();
			$('html, body').animate({
			  scrollTop: targetPost 
			}, 1000);
			return false;
		  }
		}
	  });
	});
	
	resize_videos();
	
});

jQuery(window).on("load", function() {
	adjust_header_spacer()
	resize_traits();

	(function() {
		if($('.video-responsive').length != 0) {
			$('.video-responsive').each(function(i, el) {
				var frame = $(el).find('iframe');
				frame.attr('data-width', frame.width()).attr('data-height', frame.height());
			})
		}
	})();
	
	resize_videos();
});

jQuery(window).on("resize", function() {
	adjust_header_spacer()
	resize_traits();
	resize_videos() 
});

function resize_videos() {
	var $ = jQuery;
	
	if($('.video-responsive').length != 0) {
		$('.video-responsive').each(function(i, el) {
			var widthX = Math.min($(el).width(), $(window).width());
			var frame = $(el).find('iframe');
			
			var goldenration = (typeof frame.attr('height') != "undefined" && typeof frame.attr('data-test-me') != "width") ? frame.attr('height') / frame.attr('width') : 312/555;
			
			if(frame.height()/frame.width() != goldenration) {
				frame.width(widthX).height(widthX * goldenration);
			}
		});
	}
}

function adjust_header_spacer() {
	var $ = jQuery;
	if($('#site-header-v2').length != 0) {
		$('.header-spacer, .clear-spacer').remove();
		$('<div></div>').addClass('clear').addClass('clear-spacer').insertAfter($('#site-header-v2'));
		$('<div></div>').addClass('header-spacer').height($('#site-header-v2').outerHeight()).insertAfter($('#site-header-v2'));
	}
}

function resize_traits() {
	var $ = jQuery;
	if($('.traits-list').length != 0 && $('.traits-list').children('li').length > 6) {
		$('.traits-list').removeAttr('style');
		var initHeight = $('.traits-list').height();
		$('.traits-list').animate({ 'height' : $('.traits-list li:nth-child(7)').position().top - 42 });
		$('.slide-more').remove();
		$('<h4></h4>').addClass('text-lato-bold').addClass('slide-more').css('cursor', 'pointer').click(function(e) {
			e.preventDefault();
			$('.traits-list').stop(true, true);
			if($(this).hasClass('active')) {
				$(this).removeClass('active');
				$('.traits-list').animate({ 'height' : $('.traits-list li:nth-child(7)').position().top - 42 });
				$(this).html('More Traits <span class="glyphicon glyphicon-chevron-down"></span>');
			} else {
				$(this).addClass('active');
				$('.traits-list').animate({ 'height' : $('.traits-list').get(0).scrollHeight });
				$(this).html('Show less <span class="glyphicon glyphicon-chevron-up"></span>');
			}
		}).html('More Traits <span class="glyphicon glyphicon-chevron-down"></span>').insertAfter($('.traits-list'));
	}
}